
import '../../styles/footer-style.css'
const Footer = () => {
  return (
    <div className='footer'>
        <p>© 2024 | Designed and coded by Konstantin Vchkov</p>
    </div>
  )
}

export default Footer