export const routes = [
  {
    title: "Home",
    hash: "#",
  },
  {
    title: "About",
    hash: "about",
  },
  {
    title: "Projects",
    hash: "projects",
  },
  {
    title: "Contact",
    hash: "contact",
  },
];
